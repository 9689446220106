<template>
<v-card flat class="pb-1 rounded-lg">
    <div v-if="skeleton">
        <v-skeleton-loader type="list-item-three-line,list-item-avatar-two-line,list-item-avatar-two-line" />
    </div>
    <!-- Fecha -->
    <v-card flat v-if="!skeleton && vista !== 'itinerario'" height="56" class="rounded-lg">
        <v-toolbar flat height="56" class="rounded-t-lg">
            <v-list-item class="px-0" style="max-width: 100%;">
                <v-list-item-avatar class="mr-2 rounded-lg" :color="TemaDark?'primary':'secondary'" tile width="8" height="30" min-width="8"/>
                <v-list-item-content>
                    <v-list-item-title class="headline mb-0">
                        <v-toolbar-title class="pa-0 trenos-title-toolbar text-truncate" style="line-height: 1.1;" :class="TemaDark?'grey--text text--lighten-4':'grey--text text--darken-1'">{{TituloCard}}</v-toolbar-title>
                    </v-list-item-title>
                    <v-list-item-subtitle class="font-weight-medium mb-0">
                        <span class="text-capitalize" v-if="vista == 'calendario' || !vista"></span>
                        <span class="text-capitalize" v-if="vista == 'planilla'"></span>
                        <span class="text-capitalize" v-if="vista == 'buscar'"></span>
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="vista == 'calendario' && !usuario_id">
                    <v-btn icon @click="turno_info = !turno_info" color="primary">
                        <v-icon size="20">mdi-help-circle-outline</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-toolbar>
        <v-toolbar flat class="rounded-t-lg pr-0 pl-2" v-if="!skeleton" height="56">
        </v-toolbar>
    </v-card>
    <!--Detalle Turno (detalles del dia y/ informacion del turno) -->
    <v-expand-transition>
        <v-card flat outlined v-show="turno_info" class="mx-2 rounded-lg" v-if="vista == 'calendario'">
            <TurnoInfo :info="turno.detalle" :fecha="fecha"/>
        </v-card>
    </v-expand-transition>
    <v-tabs centered v-model="tab_vista" height="42" v-if="vista == 'buscar'">
        <v-tab class="rounded-lg">informacion</v-tab>
        <v-tab class="rounded-lg">Asignaciones</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab_vista" class="pt-0">
        <v-tab-item eager transition="none" reverse-transition="none">
            <v-card outlined class="card-turno roun ded-lg pb-4" v-if="!skeleton">
                <v-container class="pa-0">
                    <v-row v-if="turno.turnos.length" class="ma-0">
                        <v-col class="pa-0">
                            <div v-for="(turno, index) in turno.turnos" :key="index">
                                <!-- Titulo Turno -->
                                <v-card class="pt-2 mx-auto text-center rounded-lg" max-width="60%" flat v-if="TurnoValidez(turno.fecha_ini, turno.fecha_ter)">
                                    <v-alert dense color="primary" text height="30" class="pt-1 mb-0" >
                                        <v-list-item-title class="text-subtitle-2 primary--text text--darken-1">
                                            <v-icon size="15" class="mb-0" color="primary" style="padding-bottom:2px;">mdi-information-outline</v-icon>
                                            {{TurnoValidez(turno.fecha_ini, turno.fecha_ter)}}
                                        </v-list-item-title>
                                    </v-alert>
                                </v-card>
                                <v-toolbar flat height="75" class="rounded-t-lg text-center mt-0">
                                    <v-list-item class=" rounded-t-lg px-0">
                                        <v-list-item-content class="py-0">
                                            <v-list-item-title class="headline pb-0 mb-0">
                                                <v-toolbar-title class="pa-0 text-capitalize trenos-title-toolbar" style="line-height: 1.1;" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">{{TurnoNombre(turno.turno, turno.nombre)}}<span class="grey--text text-body-2"> {{TurnoDuracion(turno.duracion)}} </span>
                                                    <v-chip :ripple="false" small outlined text-color="grey" v-if="turno.extra">
                                                        Extra
                                                    </v-chip>
                                                </v-toolbar-title>
                                            </v-list-item-title>
                                            <v-card max-width="50%" flat class="text-center mx-auto card-turno-detalle" v-if="TurnoHorario(Lugar(turno.entrada), Lugar(turno.salida), turno.inicio, turno.termino)">
                                                <!--<span class="grey--text text-subtitle-2">{{TipoTurno(turno.tipo)}}</span>-->
                                                <v-row class="">
                                                    <v-col cols="6" class="pr-2">
                                                        <v-list-item-subtitle class="font-weight-medium" style="text-align: end;">
                                                            <p class="text-capitalize mb-0">{{Lugar(turno.entrada)}}</p>
                                                            <p class="text-capitalize mb-0">{{turno.inicio}}</p>
                                                        </v-list-item-subtitle>
                                                    </v-col>
                                                    <v-divider vertical class="my-4" />
                                                    <v-col cols="6" class="pl-2">
                                                        <v-list-item-subtitle class="font-weight-medium" style="text-align: start;">
                                                            <p class="text-capitalize mb-0">{{Lugar(turno.salida)}}</p>
                                                            <p class="text-capitalize mb-0">{{turno.termino}}</p>
                                                        </v-list-item-subtitle>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                            <v-list-item-subtitle class="font-weight-medium text-capitalize primary--text" v-if="turno.obs" >
                                                <v-icon size="15" class="mr-0" style="margin-bottom:2px;" color="primary">mdi-information-outline</v-icon>
                                                {{turno.obs}}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-spacer></v-spacer>
                                    <v-btn icon v-if="false" disabled class="turno-opciones-btn">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                    <v-btn icon v-if="vista == 'planilla' && Edit" class="turno-opciones-btn" color="primary" @click="asignar_btn = true" :loading="CargarAsignar">
                                        <v-icon color="primary" size="20">mdi-account-plus-outline</v-icon>
                                    </v-btn>
                                    <v-btn icon v-if="vista == 'buscar' && Edit" class="turno-opciones-btn secondary" color="primary" @click="OpenEdit()">
                                        <v-icon color="primary" size="20">mdi-square-edit-outline</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <!-- Detalle asignacion -->
                                <v-card flat v-if="vista == 'planilla'">
                                    <Asignar :turnodatos="turno" :fecha="fecha" :edit="Edit" :asignar="asignar_btn"/>
                                </v-card>
                                <!-- Detalle Turno -->
                                <v-list flat class="px-2" v-if="turno.detalle.length">
                                    <v-list-item v-ripple="servicio.servicio && !only_read && (!servicio.condicion || servicio.condicion == 2)? { class: `grey--text` }: null" v-on="servicio.servicio && !only_read && (!servicio.condicion || servicio.condicion == 2)?{ click: () => ServicioDetalles(servicio.id, servicio.dir) }:{}" class="rounded-lg" v-for="(servicio, index) in turno.detalle" :key="index" :class="servicio.maquinista || servicio.condicion == 1?'servicio_anulado':''">
                                        <v-list-item-icon class="lista-turno">
                                            <v-icon color="grey" size="20" style="margin-top:2px">{{ServicioIcono(servicio.servicio, servicio.dir)}}</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content class="py-0">
                                            <v-list-item-title class="my-0 text-subtitle-2 font-weight-medium" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'" v-if="servicio.servicio">{{servicio.servicio}}
                                                <span class="text-subtitle-2 grey--text font-weight-medium" v-if="servicio.equipos">{{FormatServicios(servicio.equipos)}}</span>
                                                <v-list-item-subtitle class="font-weight-regular">{{FormatRecorrido(servicio.dir)}}</v-list-item-subtitle>
                                                <v-list-item-subtitle class="font-weight-regular" v-if="servicio.condicion == 2">
                                                    <v-chip class="mx-0 mb-2" small>
                                                        Agregado
                                                    </v-chip>
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle class="font-weight-regular" v-if="servicio.maquinista">
                                                    <v-chip class="mx-0 mb-2" small>
                                                        Asignado a <span class="text-capitalize ml-1">{{servicio.maquinista}}</span>
                                                    </v-chip>
                                                </v-list-item-subtitle>
                                            </v-list-item-title>
                                            <v-list-item-title class="my-0 text-subtitle-2 text-uppercase font-weight-medium" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'" v-else>
                                                {{servicio.dir}}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <span class="grey--text text-subtitle-2">{{servicio.inicio}} - {{servicio.termino}}</span>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                                <div class="justify-center align-center" v-if="(CantidadTurnos > index && index < CantidadTurnos - 1)">
                                    <v-divider class="mx-5"></v-divider>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row justify="center" align-content="center" v-else class="text-center ma-0">
                        <v-col cols="12">
                            <v-card flat height="230" class="justify-center align-center d-flex flex-column">
                                <v-icon size="80" color="grey lighten-1">
                                    mdi-calendar-remove-outline
                                </v-icon>
                                <span class="trenos-title-toolbar grey--text text--lighten-1">Sin turnos asignados</span>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="text-center ma-0" style="display:block;margin-bottom: -1px!important;" v-if="!in_dialog">
                        <v-chip class="ma-0 rounded-lg" label :color="TemaDark?'primary':'secondary'">
                            <v-icon left size="17" :color="TemaDark?'grey--text text--lighten-3':'primary'">mdi-clock-outline</v-icon>
                            <span class="text-subtitle-2 " :class="TemaDark?'grey--text text--lighten-3':'primary--text'">{{HoraFormat}}</span>
                        </v-chip>
                    </v-row>
                </v-container>
            </v-card>
        </v-tab-item>
        <v-tab-item eager transition="none" reverse-transition="none" v-if="vista == 'buscar'">
            <TurnoAsignaciones :turno_id="id" :rango="AsignacionFechas"/>
        </v-tab-item>
    </v-tabs-items>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="CerrarDialog()" v-if="in_dialog && vista !== 'itinerario'">
            Cerrar
        </v-btn>
    </v-card-actions>
    <Servicio :fecha="servicio.fecha" :servicio_id="servicio.id" :dir="servicio.dir" v-if="!only_read"/>
    <v-dialog v-model="dialog_edit" persistent max-width="800" transition="slide-y-reverse-transition" overlay-opacity=".25" v-if="vista == 'buscar'">
        <TurnoEdit :turno_datos="edit_datos" />
    </v-dialog>
</v-card>
</template>

<script>
import { mapState, mapMutations} from 'vuex'
import dayjs from "dayjs"
import { upperCaseFirst } from "upper-case-first"
export default {
    name: 'Turno',
    components: {
        Asignar: () => import('@/components/Asignar'),
        Servicio: () => import('@/components/Servicio'),
        TurnoEdit: () => import("@/components/TurnoEdit"),
        TurnoInfo: () => import("@/components/TurnoInfo"),
        TurnoAsignaciones: () => import('@/components/TurnoAsignaciones')
    },
    props: ['fecha', 'in_dialog', 'id', 'only_read', 'usuario_id', 'dialog_btn', 'vista'],
    data() {
        return {
            overlay: false,
            turno: {},
            skeleton: true,
            servicio: {
                id: null,
                fecha: null,
                dir: null
            },
            asignar_btn: false,
            turno_info: false,
            hora: '--:--:--',
            edit_datos: null,
            dialog_edit: false,
            axios_error: false,
            tab_vista : 0
        }
    },
    computed: {
        ...mapState(['usuario', 'dialog', 'sistema', 'update']),
        TituloCard() {
            let mensaje
            if (!this.only_read || this.vista == 'planilla' || this.vista == 'calendario' || !this.vista) {
                mensaje = this.turno.fecha
                if (this.fecha == dayjs().format('YYYY-MM-DD')) mensaje = 'Hoy'
                else mensaje = `${upperCaseFirst(dayjs(this.fecha).format('dddd DD'))} de ${upperCaseFirst(dayjs(this.fecha).format('MMMM'))}`
            }
            if (this.vista == 'buscar') {
                mensaje = 'Detalle Turno'
            }
            return mensaje
        },
        ServicioOpacity() {
            let opacity = 0.25
            if (this.dialog.turno) {
                opacity = 0
            }
            return opacity
        },
        ComponenteVista() {
            return this.componente.secundario
        },
        UpdateComponente() {
            return this.update.turno
        },
        StuatusAsignar() {
            return this.dialog.asignar
        },
        HoraFormat() {
            return this.sistema.hora
        },
        CantidadTurnos() {
            let cantidad = 0
            if (this.turno.turnos) {
                cantidad = this.turno.turnos.length
            }
            return cantidad
        },
        DialogServicio() {
            return this.dialog.servicio
        },
        Edit() {
            return this.turno.edit
        },
        DialogEdit() {
            return this.dialog.turno_edit
        },
        DialogTurno(){
            return this.dialog.turno
        },
        CargarAsignar(){
            let asignar_dialog = false
            if(this.asignar_btn) asignar_dialog = true
            return asignar_dialog 
        },
        TemaDark(){
            let dark = false
            if(this.sistema.tema == 'dark' || (this.sistema.tema == 'system' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)) dark = true
            return dark
        },
        AsignacionFechas(){
            let fechas = {}
            if(Object.keys(this.turno).length){
                fechas = {
                    inicio : this.turno.turnos[0].fecha_ini,
                    termino : this.turno.turnos[0].fecha_ter
                }
            }
            return fechas
        }
    },
    methods: {
        ...mapMutations(['ShowSnackBar', 'ErrorRequest','OpenDialog','CloseDialog']),
        //Asigna las propiedades y abre el componenente servicio
        ServicioDetalles(servicio_id, dir) {
            this.servicio = {
                fecha: this.turno.fecha,
                id: servicio_id,
                dir: dir
            }
            this.OpenDialog('servicio')
        },
        //Obtiene los datos del turno(s)
        async GetData(fecha, id, usuario_id, vista) {
            this.axios_error = false
            this.skeleton = true
            this.overlay = true
            let url
            if (fecha) {
                fecha = dayjs(fecha).format('YYYYMMDD')
                url = `/turno/${fecha}`
            }
            if (id) {
                if (vista == 'planilla') url = `/planilla/${fecha}/${id}`
                else url = `/buscar/turno/${id}`
            }
            if (usuario_id) url = `/turno/${dayjs(fecha).format('YYYYMMDD')}/${usuario_id}`
            await this.axios.get(url, {
                headers: {
                    token: this.usuario.token
                }
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                } else {
                    this.overlay = false
                    this.skeleton = false
                    this.turno = respuesta.data
                    this.asignar_btn = false
                }
            }).catch(err => {
                this.axios_error = true
                this.ErrorRequest(err)
            })
        },
        //Muestra el nombre (largo) del turno o el nombre primario del turno
        TurnoNombre(turno, nombre) {
            let turno_nombre = `Turno ${turno.toUpperCase()}` 
            if (nombre) turno_nombre = nombre
            return turno_nombre
        },
        //Muestra la duracion del turno (en el caso de tenerla)
        TurnoDuracion(duracion) {
            let turno_duracion = null
            if (duracion && (duracion != '00:00')) turno_duracion = `(${duracion})`
            return turno_duracion
        },
        //Muesta el horario del turno (en el caso de tener inicio y termino)
        TurnoHorario(entrada, salida, inicio, termino) {
            let turno_show = false
            if (entrada, salida, inicio, termino && (inicio != '00:00') && (termino != '00:00')) {
                turno_show = true
            }
            return turno_show
        },
        //Ajusta el icono correspondiente de cada detalle (direccion del servicio u otros)
        ServicioIcono(servicio, direccion) {
            let icono = null
            if (servicio > 0) {
                switch (direccion) {
                    case 'pu-li':
                        icono = 'mdi-arrow-up'
                        break
                    case 'li-pu':
                        icono = 'mdi-arrow-down'
                        break
                    case 'pu-sa':
                        icono = 'mdi-arrow-up'
                        break
                    case 'sa-pu':
                        icono = 'mdi-arrow-down'
                        break
                    case 'pu-eb':
                        icono = 'mdi-arrow-up'
                        break
                    case 'eb-pu':
                        icono = 'mdi-arrow-down'
                        break
                    case 'sa-mi':
                        icono = 'mdi-arrow-down'
                        break
                    case 'po-sa':
                        icono = 'mdi-arrow-up'
                        break
                    case 'li-ba':
                        icono = 'mdi-arrow-down'
                        break
                }
            } else {
                direccion == 'descanso' ? icono = 'mdi-coffee-outline' : icono = 'mdi-checkbox-blank-circle-outline'
            }
            return icono
        },
        //Muesta el nombre del lugar con base en sus siglas
        Lugar(lugar) {
            let nombre_lugar
            switch (lugar) {
                case 'pu':
                    nombre_lugar = 'Puerto'
                    break
                case 'li':
                    nombre_lugar = 'Limache'
                    break
                case 'sa':
                    nombre_lugar = 'S.Aldea'
                    break
                case 'eb':
                    nombre_lugar = 'El Belloto'
                    break
                case 'eb/sa':
                    nombre_lugar = 'EB/SA'
                    break
            }
            return nombre_lugar
        },
        //Formatea y muestra los equipos de cada servicio
        FormatServicios(equipos) {
            let largo_equipos = equipos.length
            let lista = '(XT '
            for (let index = 0; index < largo_equipos; index++) {
                let equipo
                if(equipos[index] < 10) equipo = `0${equipos[index]}`
                else equipo = equipos[index]
                lista += equipo
                if (index < largo_equipos - 1) lista += '-'
            }
            lista += ')'
            return lista
        },
        //Cierra el dialog turno
        CerrarDialog() {
            this.CloseDialog('turno')
        },
        //Formatea el horario de cada detalle
        FormatHorario(inicio, termino) {
            let horario = ``
            if (inicio && termino) horario = `${inicio} - ${termino}`
            if (inicio && !termino) horario = `Desde las ${inicio}`
            return horario
        },
        //Abre el panel de edicion del turno
        OpenEdit() {
            if (this.turno.turnos[0]) {
                this.edit_datos = this.turno.turnos[0]
                this.dialog_edit = true
                this.dialog.turno_edit = true
            }
        },
        //Muestra el recorrido del servicio con base en las estaciones de inicio y termino.
        FormatRecorrido(recorrido) {
            let nombre_recorrido
            switch (recorrido) {
                case 'pu-li':
                    nombre_recorrido = 'Puerto - Limache'
                    break
                case 'li-pu':
                    nombre_recorrido = 'Limache - Puerto'
                    break
                case 'pu-sa':
                    nombre_recorrido = 'Puerto - S.Aldea'
                    break
                case 'sa-pu':
                    nombre_recorrido = 'S.Aldea - Puerto'
                    break
                case 'eb-pu':
                    nombre_recorrido = 'El Belloto - Puerto'
                    break
                case 'pu-eb':
                    nombre_recorrido = 'Puerto - El Belloto'
                    break
                case 'sa-mi':
                    nombre_recorrido = 'S.Aldea - Miramar'
                    break
                case 'po-sa':
                    nombre_recorrido = 'Portales - S.Aldea'
                    break
                case 'li-ba':
                    nombre_recorrido = 'Limache - Baron'
                    break
                default:
                    break
            }
            return nombre_recorrido
        },
        //Entrega el tipo de turno con base en sus siglas.
        TipoTurno(tipo) {
            let turno_tipo
            switch (tipo) {
                case 'fm':
                    turno_tipo = 'Full Time Mañana'
                    break
                case 'ft':
                    turno_tipo = 'Full Time Tarde'
                    break
                case 'lm':
                    turno_tipo = 'Part Time 80 Mañana'
                    break
                case 'lt':
                    turno_tipo = 'Part Time 80 Tarde'
                    break
                case 'lms':
                    turno_tipo = 'Part Time 120 Mañana'
                    break
                case 'lts':
                    turno_tipo = 'Part Time 120 Tarde'
                    break
                case 'sm':
                    turno_tipo = 'Sabado Mañana'
                    break
                case 'st':
                    turno_tipo = 'Sabado Tarde'
                    break
                case 'dm':
                    turno_tipo = 'Domingo Mañana'
                    break
                case 'dt':
                    turno_tipo = 'Domingo Tarde'
                    break
            }
            return turno_tipo
        },
        //Muesta un alert en caso de que el turno no sea valido.
        TurnoValidez(fecha_inicio, fecha_termino) {
            let mensaje,
            fecha_actual = dayjs().format('YYYY-MM-DD')
            if(fecha_inicio && fecha_termino){
                if (fecha_inicio == fecha_termino) {
                    if(fecha_inicio == dayjs().format('YYYY-MM-DD')) mensaje = `Válido solo por hoy`
                    else  mensaje = `Válido solo ${dayjs(fecha_inicio).format('DD')} de ${dayjs(fecha_inicio).format('MMMM')}`
                }
                else{
                    if (fecha_actual == fecha_inicio) mensaje = `Válido desde hoy`
                    if (fecha_actual < fecha_inicio) mensaje = `Válido desde ${dayjs(fecha_inicio).format('DD')} de ${dayjs(fecha_inicio).format('MMMM')}`
                    if (fecha_actual == fecha_termino) mensaje = `Válido hasta hoy`
                    //if (fecha_actual > fecha_termino) mensaje = `Este turno ya no es válido`
                }
            }else{
                if(fecha_inicio){
                    if (fecha_actual < fecha_inicio) mensaje = `Válido desde ${dayjs(fecha_inicio).format('DD')} de ${dayjs(fecha_inicio).format('MMMM')}`
                }
                if(fecha_termino){
                    if (fecha_actual > fecha_termino) mensaje = `Este turno ya no es válido`
                }
            }
            return mensaje
        },
    },
    mounted() {
        this.GetData(this.fecha, this.id, this.usuario_id, this.vista)
    },
    watch: {
        id: function (val) {
            //Detecta cuando se cambia la id en la vista itinerario (dialog servicio)
            if(val && this.vista == 'itinerario') this.GetData(this.fecha, this.id, this.usuario_id, this.vista)
        },
        UpdateComponente: function (val) {
            if (val) {
                this.GetData(this.fecha, this.id, this.usuario_id, this.vista)
                if(this.vista == 'calendario') setTimeout(() => { this.update.turno = false}, 500) //Permite que en la vista 'Calendario' ambos componentes se refresquen.
                else this.update.turno = false
            }
        },
        StuatusAsignar: function (val) {
            if (!val) this.asignar_btn = false
        },
        DialogServicio(val) {
            if (!val) {
                this.servicio = {
                    id: null,
                    fecha: null,
                    dir: null
                }
            }
        },
        DialogEdit(val) {
            if (!val) {
                this.dialog_edit = false
                this.edit_datos = null
            }
        },
        DialogTurno: function (val) {
            //Aplicable a vista calendario.
            if(val && this.in_dialog) this.GetData(this.fecha, this.id, this.usuario_id, this.vista)
            //if(val && !this.in_dialog) this.GetData(this.fecha, this.id, this.usuario_id, this.vista)
            //Esconde el componente info (il) en vistas distintas a calendario.
            if(!val && this.in_dialog) this.turno_info = false
            this.tab_vista = 0
        },
    },
}
</script>

<style scoped>
.v-application .mb-4 {
    margin-bottom: 0px !important;
}

.lista-turno {
    margin: 10px 0;
    min-width: 20px;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 14px;
}

.toolbar-turno {
    z-index: 2;
    background-color: transparent !important;
}

.titulo-turno {
    top: -3.8rem;
    margin-bottom: -4rem;
    max-width: 85%;
}

.v-chip.v-size--small {
    border-radius: 12px;
    font-size: 12px;
    height: 19px;
}

.v-list-item__action {
    align-self: center;
    margin: 10px 0;
}

.v-list-item {
    align-items: center;
    display: flex;
    flex: 1 1 100%;
    letter-spacing: normal;
    min-height: 45px;
    outline: none;
    padding: 0 16px;
    position: relative;
    text-decoration: none;
}

.v-chip.v-size--default {
    border-radius: 16px;
    font-size: 14px;
    height: 24px;
}

.card-turno {
    border: none;
}

.turno-opciones-btn {
    position: absolute;
    right: 2rem;
    height: 42px !important;
    width: 42px !important;
}

.fechadetalle {
    min-height: 30px;
}

.card-turno-detalle {
    margin-top: -1px;
}

.servicio_anulado{
    opacity: .5 !important;
}
</style>
